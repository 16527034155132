.rotate-animation {
  animation: rotate-animation 2s infinite linear;
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
